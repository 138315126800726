import React from 'react';
import capturamaLogo from '../assets/capturama_2021_centered.png';

interface CapturamaLoaderOverlayProps {
  isLoading: boolean;
}

const CapturamaLoaderOverlay: React.FC<CapturamaLoaderOverlayProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-[500]">
      <div className="text-center">
        <img
          src={capturamaLogo}
          alt="Capturama Logo"
          className="w-44 h-44 mb-4 animate-spin"
          style={{ animationDuration: '2s' }}
        />
        <p className="mt-2 text-gray-600">Chargement...</p>
      </div>
    </div>
  );
};

export default CapturamaLoaderOverlay;
import { useState, useRef, useCallback } from 'react';
import { AutocompleteFeature } from '../types/AutocompleteFeature';
import { Parcelle } from '../types/Parcelle';
import { ParcelleSearched, ParsedParcelleSearched } from '../types/ParcelleSearched';
import { LatLng } from 'leaflet';
import { useAppSelector, useAppDispatch } from './reduxHooks';
import { setToken } from '../store/userSlice';
import { getPolygonCentroid } from '../utils/mapUtils';

const BASE_API_URL: string = import.meta.env.VITE_BASE_API_URL;

type SearchResult = AutocompleteFeature | ParsedParcelleSearched;

export const useSearch = (
  setCenter: (center: [number, number]) => void,
  setZoom: (zoom: number) => void,
  setSelectedParcelle: (parcelle: Parcelle | null) => void,
  handleClosePopup: () => void,
  setSelectedAddress: React.Dispatch<React.SetStateAction<LatLng | null>>,
  fetchParcelleAndAddresses: (lng: number, lat: number) => void,
) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [error, setError] = useState<string | null>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const token = useAppSelector(state => state.user.token);
  const dispatch = useAppDispatch();

  const fetchAutocompleteResults = async (query: string): Promise<AutocompleteFeature[]> => {
    try {
      const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`);
      const data = await response.json();
      return data.features;
    } catch (errorFetching) {
      console.error('Error fetching autocomplete results:', errorFetching);
      return [];
    }
  };

  const fetchParcelleResults = async (query: string): Promise<ParsedParcelleSearched[]> => {
    if (!token) {
      console.error('No token available');
      return [];
    }

    try {
      const response = await fetch(`${BASE_API_URL}/module/common/plots/${encodeURIComponent(query)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(setToken(null));
          throw new Error('Unauthorized: Please log in again');
        }
        if (response.status === 404) {
          return [];
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const apiParcelle: ParcelleSearched = await response.json();
      const parsedGeometry = JSON.parse(apiParcelle.geometry);

      // Calculate center coordinates from the geometry
      const coordinates = parsedGeometry.coordinates[0][0];
      const { lat, lng } = getPolygonCentroid(coordinates);

      const parsedParcelle: ParsedParcelleSearched = {
        ...apiParcelle,
        geometry: parsedGeometry,
        lat: lat,
        lon: lng,
      };

      return [parsedParcelle];
    } catch (errorFetching) {
      console.error('Error fetching parcelle results:', errorFetching);
      return [];
    }
  };

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParcelle(null);
    handleClosePopup();
    const query = e.target.value;
    setSearchQuery(query);
    setError(null);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(async () => {
      if (query.length > 2) {
        try {
          const addressResults = await fetchAutocompleteResults(query);
          let parcelleResults: ParsedParcelleSearched[] = [];
          const parcelleRegex = /^(?:\d{5}|2A\d{3}|2B\d{3})\d{3,4}[A-Z]{1,2}\d{4}$/;
          if (parcelleRegex.test(query)) {
            parcelleResults = await fetchParcelleResults(query);
          }
          const allResults = [...addressResults, ...parcelleResults];
          setSearchResults(allResults);
          if (allResults.length === 0) {
            setError('Aucun résultat trouvé');
          }
        } catch {
          setError("Une erreur s'est produite lors de la recherche");
        }
      } else {
        setSearchResults([]);
      }
    }, 300);
  }, [setSelectedParcelle, handleClosePopup, token]);

  const handleSelectResult = useCallback((result: SearchResult) => {
    setSearchQuery('properties' in result ? result.properties.label : result.id);
    setSearchResults([]);
    setError(null);

    if ('properties' in result) {
      // AutocompleteFeature
      if (result.properties.type === 'municipality') {
        const coordinates = result.geometry.coordinates;
        const newCenter: [number, number] = [coordinates[1], coordinates[0]];
        setCenter(newCenter);
        setZoom(14);
        setSelectedAddress(new LatLng(newCenter[0], newCenter[1]));
        setSelectedParcelle(null);
      } else {
        const coordinates = result.geometry.coordinates;
        const newCenter: [number, number] = [coordinates[1], coordinates[0]];
        setCenter(newCenter);
        setZoom(19);
        setSelectedAddress(new LatLng(newCenter[0], newCenter[1]));
        setSelectedParcelle(null);
      }
    } else {
      //same as a click on the given parcelle
      fetchParcelleAndAddresses(result.lon, result.lat);
      setCenter([result.lat, result.lon]);
      setZoom(19);
    }
  }, [setCenter, setZoom, setSelectedAddress, setSelectedParcelle]);

  const performSearch = useCallback(async (query: string) => {
    setSearchQuery(query);
    setSelectedParcelle(null);
    handleClosePopup();
    setError(null);

    if (query.length > 2) {
      try {
        let parcelleResults: ParsedParcelleSearched[] = [];
        const addressResults = await fetchAutocompleteResults(query);
        
        // Check if the query matches the parcelle regex
        const parcelleRegex = /^(?:\d{5}|2A\d{3}|2B\d{3})\d{3,4}[A-Z]{1,2}\d{4}$/;
        if (parcelleRegex.test(query)) {
          parcelleResults = await fetchParcelleResults(query);
        }
        const allResults = [...parcelleResults, ...addressResults];
        setSearchResults(allResults);

        if (allResults.length > 0) {
          handleSelectResult(allResults[0]);
        } else {
          setError('Aucun résultat trouvé');
        }
      } catch {
        setError("Une erreur s'est produite lors de la recherche");
      }
    } else {
      setSearchResults([]);
    }
  }, [setSelectedParcelle, handleClosePopup, handleSelectResult, token]);

  return {
    searchQuery,
    searchResults,
    handleSearchChange,
    handleSelectResult,
    performSearch,
    error,
  };
};
import React, { useState, KeyboardEvent, MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { setError, userAuth } from '../store/userSlice';
import SpinnerComponent from './LoginFormComponents/SpinnerComponent';
import homeImage from '../assets/home.png';
import capturamaLogo from '../assets/capturama_2021_centered.png';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const processing = useAppSelector(state => state.user.processing);
  const errMessage = useAppSelector(state => state.user.error);
  const dispatch = useAppDispatch();

  const loginClick = (e: MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (email !== '' && password !== '') {
      dispatch(userAuth({ email, password, rememberMe }));
    } else {
      dispatch(setError('Veuillez saisir vos identifiants Capturama.'));
    }
  };

  const rememberMeOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRememberMe(e.target.checked);
  };

  const loginEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      loginClick(event as unknown as MouseEvent<HTMLButtonElement>); // Cast to MouseEvent to reuse loginClick
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${homeImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
        <div className="p-4 md:p-auto flex md:flex-row flex-col items-center justify-center min-h-screen bg-gray-100" style={backgroundStyle}>
            <img
              src={capturamaLogo}
              alt="Capturama Logo"
              className="md:w-1/4 w-1/3 max-w-56 mr-0 md:mr-12 xl:mr-36 rounded-full bg-white/10"
            />
            <div className="p-4 text-left bg-white/85 shadow-lg rounded-md mt-10 w-auto sm:w-96">
                <h3 className="text-xl font-bold text-center">Cadastre - Connexion</h3>
                {errMessage && <div className="mt-2 mb-2 text-center text-red-600 text-xs">{errMessage}</div>}
                <form action="">
                    <div className="mt-2">
                        <div>
                            <input 
                                type="text" 
                                placeholder="Adresse email"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-600"
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyUp={loginEnter}
                                disabled={processing}
                            />
                        </div>
                        <div className="mt-2">
                            <input 
                                type="password" 
                                placeholder="Mot de passe"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-600"
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={loginEnter}
                                disabled={processing}
                            />
                        </div>
                        <div className="mt-4 flex flex-row justify-between gap-5">
                            <label className="inline-flex items-center ml-1">
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 rounded"
                                    checked={rememberMe}
                                    onChange={rememberMeOnChange}
                                    disabled={processing}
                                />
                                <span className="ml-2 text-sm text-gray-700 font-semibold">Rester connecté</span>
                            </label>
                            <a href="https://www.capturama.fr/login" target='_blank' className="text-sm text-indigo-600 hover:underline mr-1  font-semibold" >Mot de passe oublié ?</a>
                        </div>
                        <div className="flex items-baseline justify-between">
                            <button
                                className="px-6 py-2 mt-4 text-white font-semibold bg-green-600 rounded-lg hover:bg-green-900 w-full"
                                disabled={processing}
                                onClick={loginClick}
                            >
                                {processing ? <SpinnerComponent show={true} /> : 'Se connecter'}
                            </button>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
  );
};

export default LoginForm;
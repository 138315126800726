// MapComponent.tsx
import React, { useEffect, useState } from 'react';
import { MapContainer, ZoomControl, useMap } from 'react-leaflet';
import { OverzoomTileLayer } from './OverzoomTileLayer';
import { SearchBar } from './SearchBar';
import { MapControls } from './MapControls';
import { MeasurementTool } from './MeasurementTool';
import { MapEvents } from './MapEvents';
import { SelectedParcellePopup } from './SelectedParcellePopup';
import { MeasuredElements } from './MeasuredElements';
import { useParcelle } from '../hooks/useParcelle';
import { useMeasurement } from '../hooks/useMeasurement';
import { useSearch } from '../hooks/useSearch';
import { MeasurementPoints } from './MeasurementPoints';
import { LatLng } from 'leaflet';
import { AddressMarker } from './AddressMarker';
import { SelectedParcellePolygon } from './SelectedParcellePolygon';
import CapturamaLoaderOverlay from './CapturamaLoaderOverlay';
{/*import applicationVersion from '../../application_version.txt?raw';*/}

const MapUpdater = ({ center, zoom }: { center: [number, number]; zoom: number }) => {
  const map = useMap();
  React.useEffect(() => {
    map.flyTo(center, zoom, {
      duration: 0.75,
    });
  }, [map, center, zoom]);
  return null;
};

interface MapComponentProps {
  initialSearch?: string;
  setInitialSearch?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const MapComponent: React.FC<MapComponentProps> = ({ initialSearch, setInitialSearch }) => {
  const [center, setCenter] = useState<[number, number]>([46.6611, 2.06194]);
  const [zoom, setZoom] = useState<number>(7);
  const [isCadastre, setIsCadastre] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<LatLng | null>(null);

  const { 
    selectedParcelle, 
    setSelectedParcelle, 
    handleMapClick, 
    parcelleAddresses,
    isLoading: isParcelleLoading,
    fetchParcelleAndAddresses,
  } = useParcelle(setCenter, setZoom);

  const { 
    isMeasuring, measurementPoints, measurements, selectedMeasurement, openPopupId,
    handleMeasureClick, handleCancelMeasurement, handleFinishMeasurement,
    handleMeasurementClick, handleClosePopup, handleDeleteMeasurement,
    addMeasurementPoint,
  } = useMeasurement(setCenter, setZoom);

  const { 
    searchQuery, 
    searchResults, 
    handleSearchChange, 
    handleSelectResult, 
    performSearch,
    error: searchError,
  } = useSearch(setCenter, setZoom, setSelectedParcelle, handleClosePopup, setSelectedAddress, fetchParcelleAndAddresses);

  useEffect(() => {
    if (initialSearch && setInitialSearch) {
      performSearch(initialSearch);
      setInitialSearch(undefined);
    }
  }, [initialSearch, performSearch]);

  return (
    <div className="relative h-screen w-full">
      <MapContainer
        center={center}
        zoom={zoom}
        zoomControl={false}
        maxZoom={20}
        style={{ height: '100%', width: '100%' }}
        attributionControl={false}
      >
        <MapUpdater center={center} zoom={zoom} />
        <OverzoomTileLayer isCadastre={isCadastre} />
        <ZoomControl position="bottomright" />
        <MapEvents 
          isMeasuring={isMeasuring}
          measurements={measurements}
          handleMapClick={handleMapClick}
          handleMeasurementClick={handleMeasurementClick}
          handleClosePopup={handleClosePopup}
          addMeasurementPoint={addMeasurementPoint}
          openPopupId={openPopupId}
          selectedMeasure={selectedMeasurement}
        />
        <SelectedParcellePopup 
          selectedParcelle={selectedParcelle} 
          setSelectedParcelle={setSelectedParcelle}
          parcelleAddresses={parcelleAddresses}
          isLoading={isParcelleLoading}
        />
        <SelectedParcellePolygon selectedParcelle={selectedParcelle} />
        <MeasuredElements 
          measurements={measurements}
          openPopupId={openPopupId}
          selectedMeasure={selectedMeasurement}
          handleDeleteMeasurement={handleDeleteMeasurement}
          handleClosePopup={handleClosePopup}
        />
        <MeasurementPoints points={measurementPoints} isActive={isMeasuring} />
        {selectedAddress && (
          <AddressMarker position={selectedAddress} />
        )}
      </MapContainer>
      <MapControls 
        isCadastre={isCadastre}
        setIsCadastre={setIsCadastre}
        handleMeasureClick={handleMeasureClick}
      />
      <SearchBar 
        searchQuery={searchQuery}
        searchResults={searchResults}
        handleSearchChange={handleSearchChange}
        handleSelectResult={handleSelectResult}
        error={searchError}
        setCenter={setCenter}
        setZoom={setZoom}
        setSelectedAddress={setSelectedAddress}
        handleClosePopup={handleClosePopup}
        setSelectedParcelle={setSelectedParcelle}
      />
      {isMeasuring && (
        <MeasurementTool 
          measurementPoints={measurementPoints}
          handleCancelMeasurement={handleCancelMeasurement}
          handleFinishMeasurement={handleFinishMeasurement}
        />
      )}
      <CapturamaLoaderOverlay isLoading={isParcelleLoading} />

      {/* Display version */}
      {/* {applicationVersion} */}
    </div>
  );
};

export default MapComponent;
import L from 'leaflet';

export function formatCoordinates(lat: number, lng: number): string {
  const latDeg = Math.floor(Math.abs(lat));
  const latMin = Math.floor((Math.abs(lat) - latDeg) * 60);
  const latSec = ((Math.abs(lat) - latDeg - latMin / 60) * 3600).toFixed(2);
  const latDir = lat >= 0 ? 'N' : 'S';

  const lngDeg = Math.floor(Math.abs(lng));
  const lngMin = Math.floor((Math.abs(lng) - lngDeg) * 60);
  const lngSec = ((Math.abs(lng) - lngDeg - lngMin / 60) * 3600).toFixed(2);
  const lngDir = lng >= 0 ? 'E' : 'W';

  return `${latDeg}° ${latMin}' ${latSec}" ${latDir} / ${lngDeg}° ${lngMin}' ${lngSec}" ${lngDir}`;
}

export function calculatePolygonArea(points: L.LatLng[]): number {
  if (points.length < 3) {
    return 0;
  }

  const earthRadius = 6371000; // Earth's mean radius in meters

  function toRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }

  let total = 0;

  for (let i = 0; i < points.length; i++) {
    const p1 = points[i];
    const p2 = points[(i + 1) % points.length];
        
    const lat1 = toRadians(p1.lat);
    const lon1 = toRadians(p1.lng);
    const lat2 = toRadians(p2.lat);
    const lon2 = toRadians(p2.lng);

    total += (lon2 - lon1) * (2 + Math.sin(lat1) + Math.sin(lat2));
  }

  total = total * earthRadius * earthRadius / 2;

  return Math.abs(total);
}

export function calculateDistance(points: L.LatLng[]): number {
  let totalDistance = 0;
  for (let i = 1; i < points.length; i++) {
    totalDistance += points[i - 1].distanceTo(points[i]);
  }
  return totalDistance;
}

export function formatArea(areaInSquareMeters?: number): string {
  if (!areaInSquareMeters) {
    return '0 m²';
  }
  if (areaInSquareMeters >= 1000000) {
    return `${(areaInSquareMeters / 1000000).toFixed(2)} km²`;
  }
  return `${areaInSquareMeters.toFixed(0)} m²`;
}

export function formatDistance(distanceInMeters?: number): string {
  if (!distanceInMeters) {
    return '0 m (0 miles)';
  }
  if (distanceInMeters >= 1000) {
    return `${(distanceInMeters / 1000).toFixed(2)} km (${(distanceInMeters / 1609.34).toFixed(2)} miles)`;
  }
  return `${distanceInMeters.toFixed(0)} m (${(distanceInMeters / 1609.34).toFixed(2)} miles)`;
}
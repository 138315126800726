import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

interface OverzoomTileLayerProps {
  isCadastre: boolean;
}

interface TileSource {
  url: string;
  options: L.TileLayerOptions;
}

export const OverzoomTileLayer: React.FC<OverzoomTileLayerProps> = ({ isCadastre }) => {
  const map = useMap();
  const layersRef = useRef<{ base: L.TileLayer | null; cadastre: L.TileLayer | null }>({ base: null, cadastre: null });
  const [orthoSourceIndex, setOrthoSourceIndex] = useState(0);
  const [planSourceIndex, setPlanSourceIndex] = useState(0);
  const [cadastreSourceIndex, setCadastreSourceIndex] = useState(0);

  useEffect(() => {
    const tileSources: Record<string, TileSource[]> = {
      ortho: [
        {
          url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
          options: { maxNativeZoom: 19, maxZoom: map.getMaxZoom() },
        },
        {
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          options: { maxNativeZoom: 19, maxZoom: map.getMaxZoom() },
        },
      ],
      plan: [
        {
          url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
          options: { maxNativeZoom: 19, maxZoom: map.getMaxZoom() },
        },
        {
          url: 'https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
          options: { maxNativeZoom: 20, maxZoom: map.getMaxZoom() },
        },
      ],
      cadastre: [
        {
          url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=PCI%20vecteur&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
          options: { opacity: 1.0, maxNativeZoom: 19, maxZoom: map.getMaxZoom() },
        },
      ],
    };

    const createLayerWithFallback = (
      sources: TileSource[],
      sourceIndex: number,
      setSourceIndex: React.Dispatch<React.SetStateAction<number>>,
    ): L.TileLayer => {
      const maxRetries = 3;

      const layer = L.tileLayer(sources[sourceIndex].url, sources[sourceIndex].options);

      layer.on('tileerror', (error: L.TileErrorEvent) => {
        const tile = error.tile as HTMLImageElement;
        const currentUrl = tile.src;
        const retryMatch = currentUrl.match(/&retry=(\d+)/);
        const currentRetry = retryMatch ? parseInt(retryMatch[1], 10) : 0;

        if (currentRetry >= maxRetries) {
          if (sourceIndex < sources.length - 1) {
            setSourceIndex(sourceIndex + 1);
          } else {
            tile.style.display = 'none';
          }
          return;
        }

        const nextRetry = currentRetry + 1;
        tile.src = currentUrl.replace(/&retry=\d+/, '') + `&retry=${nextRetry}`;
      });

      return layer;
    };

    const updateLayers = () => {
      if (layersRef.current.base) {
        map.removeLayer(layersRef.current.base);
      }

      if (isCadastre) {
        layersRef.current.base = createLayerWithFallback(tileSources.plan, planSourceIndex, setPlanSourceIndex);
      } else {
        layersRef.current.base = createLayerWithFallback(tileSources.ortho, orthoSourceIndex, setOrthoSourceIndex);
      }
      layersRef.current.base.addTo(map);

      layersRef.current.cadastre = createLayerWithFallback(tileSources.cadastre, cadastreSourceIndex, setCadastreSourceIndex);
      layersRef.current.cadastre.addTo(map);
    };

    updateLayers();

    return () => {
      if (layersRef.current.base) {
        map.removeLayer(layersRef.current.base);
      }
      if (layersRef.current.cadastre) {
        map.removeLayer(layersRef.current.cadastre);
      }
    };
  }, [map, isCadastre, orthoSourceIndex, planSourceIndex, cadastreSourceIndex]);

  return null;
};
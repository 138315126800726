import L from 'leaflet';

export function createBounds(points: L.LatLng[]): L.LatLngBounds {
  return L.latLngBounds(points);
}

export function getCenter(bounds: L.LatLngBounds): L.LatLng {
  return bounds.getCenter();
}

export function flyToBounds(map: L.Map, bounds: L.LatLngBounds, padding: number = 50): void {
  map.flyToBounds(bounds, { padding: [padding, padding] });
}

export function addMarker(map: L.Map, latlng: L.LatLng, options?: L.MarkerOptions): L.Marker {
  return L.marker(latlng, options).addTo(map);
}

export function removeMarker(map: L.Map, marker: L.Marker): void {
  map.removeLayer(marker);
}

function isPointInPolygon(point: number[], polygon: number[][]): boolean {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0], yi = polygon[i][1];
    const xj = polygon[j][0], yj = polygon[j][1];
    const intersect = ((yi > point[1]) !== (yj > point[1]))
          && (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
}

export function getPolygonCentroid(vertices: number[][]): { lat: number, lng: number } {
  function shrinkPolygon(polygon: number[][], factor: number): number[][] {
    const result: number[][] = [];
    const n = polygon.length;
    for (let i = 0; i < n; i++) {
      const prev = polygon[(i - 1 + n) % n];
      const curr = polygon[i];
      const next = polygon[(i + 1) % n];

      const dx1 = curr[0] - prev[0];
      const dy1 = curr[1] - prev[1];
      const dx2 = next[0] - curr[0];
      const dy2 = next[1] - curr[1];

      const nx1 = -dy1;
      const ny1 = dx1;
      const nx2 = -dy2;
      const ny2 = dx2;

      const mx = (nx1 + nx2) / 2;
      const my = (ny1 + ny2) / 2;
      const length = Math.sqrt(mx * mx + my * my);

      result.push([
        curr[0] + factor * mx / length,
        curr[1] + factor * my / length,
      ]);
    }
    return result;
  }

  function polygonArea(polygon: number[][]): number {
    let area = 0;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      area += (polygon[j][0] + polygon[i][0]) * (polygon[j][1] - polygon[i][1]);
    }
    return Math.abs(area / 2);
  }

  function weightedAverageCentroid(polygon: number[][]): number[] {
    let centerX = 0;
    let centerY = 0;
    let totalArea = 0;

    for (let i = 0; i < polygon.length; i++) {
      const j = (i + 1) % polygon.length;
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];

      const area = (xi * yj) - (xj * yi);
      totalArea += area;

      centerX += (xi + xj) * area;
      centerY += (yi + yj) * area;
    }

    if (totalArea === 0) {
      // Fallback to arithmetic mean if the polygon has no area
      centerX = polygon.reduce((sum, v) => sum + v[0], 0) / polygon.length;
      centerY = polygon.reduce((sum, v) => sum + v[1], 0) / polygon.length;
    } else {
      const factor = 1 / (3 * totalArea);
      centerX *= factor;
      centerY *= factor;
    }

    return [centerX, centerY];
  }

  const originalArea = polygonArea(vertices);
  let currentPolygon = vertices;
  let centerPoint: number[] = [0, 0];

  const shrinkFactor = Math.sqrt(originalArea) * 0.01;
  const minArea = originalArea * 0.0001;
  const maxIterations = 100; // Maximum number of shrinkage iterations

  let iterations = 0;
  while (polygonArea(currentPolygon) > minArea && iterations < maxIterations) {
    const shrunkPolygon = shrinkPolygon(currentPolygon, shrinkFactor);

    const avgX = shrunkPolygon.reduce((sum, v) => sum + v[0], 0) / shrunkPolygon.length;
    const avgY = shrunkPolygon.reduce((sum, v) => sum + v[1], 0) / shrunkPolygon.length;
    centerPoint = [avgX, avgY];

    if (isPointInPolygon(centerPoint, vertices)) {
      break;
    }

    currentPolygon = shrunkPolygon;
    iterations++;
  }

  // If shrinkage algorithm didn't find a suitable point, use weighted average
  if (!isPointInPolygon(centerPoint, vertices) || iterations >= maxIterations) {
    centerPoint = weightedAverageCentroid(vertices);
  }

  // Final fallback if the calculated point is still not in the polygon
  if (!isPointInPolygon(centerPoint, vertices)) {
    centerPoint = [
      vertices.reduce((sum, v) => sum + v[0], 0) / vertices.length,
      vertices.reduce((sum, v) => sum + v[1], 0) / vertices.length,
    ];
  }

  return { lng: centerPoint[0], lat: centerPoint[1] };
}


function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function calculateHaversineDistance(point1: number[], point2: number[]): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = degreesToRadians(point2[1] - point1[1]);
  const dLon = degreesToRadians(point2[0] - point1[0]);
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(point1[1])) * Math.cos(degreesToRadians(point2[1])) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}


function findLongestDiagonal(points: number[][]): number {
  let maxDistance = 0;
  for (let i = 0; i < points.length; i++) {
    for (let j = i + 1; j < points.length; j++) {
      const distance = calculateHaversineDistance(points[i], points[j]);
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    }
  }
  return maxDistance;
}

export function getZoomLevelForPolygon(polygonPoints: number[][]): number {
  if (polygonPoints.length < 2) {
    console.warn('Not enough points to form a line or polygon');
    return 19; // Default zoom level for points
  }

  const maxDistance = findLongestDiagonal(polygonPoints);
  
  // These values can be adjusted based on your specific needs
  const MAX_ZOOM = 20;
  const MIN_ZOOM = 1;
  const REFERENCE_DISTANCE = 7; // in km, distance at which we want zoom level 14

  // Calculate zoom based on the longest diagonal
  // This logarithmic scale ensures zoom is always positive
  const zoom = 14 - Math.log2(maxDistance / REFERENCE_DISTANCE);


  // Clamp the zoom level to valid values
  return Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, Math.round(zoom)));
}
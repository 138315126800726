import React from 'react';
import { Polygon } from 'react-leaflet';
import { Parcelle } from '../types/Parcelle';
import { LatLngExpression } from 'leaflet';


interface SelectedParcellePolygonProps {
  selectedParcelle: Parcelle | null;
}

export const SelectedParcellePolygon: React.FC<SelectedParcellePolygonProps> = ({ selectedParcelle }) => {
  if (!selectedParcelle) return null;

  const coordinates: LatLngExpression[][] = selectedParcelle.geometry.coordinates[0].map(ring =>
    ring.map(coord => [coord[1], coord[0]] as LatLngExpression),
  );

  return (
    <Polygon
      positions={coordinates}
      pathOptions={{
        color: 'blue',
        weight: 3,
        fillColor: 'transparent',
      }}
    />
  );
};
import { useState, useCallback } from 'react';
import L from 'leaflet';
import { Parcelle } from '../types/Parcelle';
import { Address } from '../types/Address';
import { useAppSelector, useAppDispatch } from './reduxHooks';
import { setToken } from '../store/userSlice';
import { getPolygonCentroid, getZoomLevelForPolygon } from '../utils/mapUtils';

const BASE_API_URL: string = import.meta.env.VITE_BASE_API_URL;

interface ApiParcelle {
  id: string;
  arpente: boolean;
  commune: string;
  created: string;
  numero: string;
  geometry: string;
  ogcFid: number;
  prefixe: string;
  section: string;
  surfaceParcelle: number;
  updated: string;
}

interface ParcelleWithAddresses {
  parcelle: Parcelle | null;
  addresses: Address[];
}

export const useParcelle = (
  setCenter: (center: [number, number]) => void,
  setZoom: (zoom: number) => void,
) => {
  const [parcelleData, setParcelleData] = useState<ParcelleWithAddresses>({ parcelle: null, addresses: [] });
  const token = useAppSelector(state => state.user.token);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const fetchParcelleAndAddresses = useCallback(async (lng: number, lat: number) => {
    if (!token) {
      console.error('No token available');
      return;
    }
    setIsLoading(true);
    try {
      const parcelleResponse = await fetch(`${BASE_API_URL}/module/common/plots/geolocation/${lng}/${lat}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!parcelleResponse.ok) {
        if (parcelleResponse.status === 401) {
          dispatch(setToken(null));
          throw new Error('Unauthorized: Please log in again');
        }
        throw new Error(`HTTP error! status: ${parcelleResponse.status}`);
      }

      const parcelleDataResponse: ApiParcelle[] = await parcelleResponse.json();
      
      if (parcelleDataResponse.length > 0) {
        const parcelle = parcelleDataResponse[0];
        const parsedGeometry = JSON.parse(parcelle.geometry);
        const newParcelle: Parcelle = {
          ...parcelle,
          geometry: parsedGeometry,
        };

        const addressesResponse = await fetch(`${BASE_API_URL}/module/common/plots/${parcelle.id}/addresses`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!addressesResponse.ok) {
          throw new Error(`HTTP error! status: ${addressesResponse.status}`);
        }

        const addresses: Address[] = await addressesResponse.json();

        //get the center of the parcelle
        const { lat: latCenter, lng : lngCenter } = getPolygonCentroid(parsedGeometry.coordinates[0][0]);
        setCenter([latCenter, lngCenter]);
        //calculate the zoom level based on the parcelle surface
        setZoom(getZoomLevelForPolygon(parsedGeometry.coordinates[0][0]));
        setParcelleData({
          parcelle: newParcelle,
          addresses: addresses,
        });
      } else {
        setParcelleData({ parcelle: null, addresses: [] });
      }
    } catch (error) {
      console.error('Error fetching parcelle data:', error);
      setParcelleData({ parcelle: null, addresses: [] });
    }
    setIsLoading(false);
  }, [token, dispatch]);

  const handleMapClick = (e: L.LeafletMouseEvent) => {
    const { lat, lng } = e.latlng;
    fetchParcelleAndAddresses(lng, lat);
  };

  return { 
    selectedParcelle: parcelleData.parcelle, 
    parcelleAddresses: parcelleData.addresses, 
    handleMapClick,
    setSelectedParcelle: (parcelle: Parcelle | null) => setParcelleData(prev => ({ ...prev, parcelle })),
    isLoading,
    fetchParcelleAndAddresses,
  };
};
import React from 'react';
import { Marker } from 'react-leaflet';
import { LatLng, DivIcon } from 'leaflet';

interface AddressMarkerProps {
  position: LatLng;
}

const addressIcon = new DivIcon({
  html: `
    <div style="cursor: grab;">
      <img 
        src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png"
        style="width: 25px; height: 41px;"
      />
    </div>
  `,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  className: 'address-marker-icon',
});

export const AddressMarker: React.FC<AddressMarkerProps> = ({ position }) => {
  return (
    <Marker position={position} icon={addressIcon}>
    </Marker>
  );
};
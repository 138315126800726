import React from 'react';
import { MeasurementPoint } from '../types/MeasurementPoint';
import { calculateDistance, calculatePolygonArea as calculateArea, formatDistance, formatArea } from '../utils/measurementUtils';
import { XMarkIcon, ArrowUturnLeftIcon, CheckIcon } from '@heroicons/react/20/solid';


interface MeasurementToolProps {
  measurementPoints: MeasurementPoint[];
  handleCancelMeasurement: () => void;
  handleFinishMeasurement: () => void;
}

export const MeasurementTool: React.FC<MeasurementToolProps> = ({
  measurementPoints,
  handleCancelMeasurement,
  handleFinishMeasurement,
}) => {
  return (
    <div className="absolute bottom-4 left-4 right-4 bg-white p-4 rounded-md shadow-md z-[1000] max-w-md">
      <h2 className="text-xl font-bold mb-5">Mesurer les distances et superficies</h2>
      <h3 className="text-md font-semibold">Dernier point</h3>
      <p>{measurementPoints.length > 0 && measurementPoints[measurementPoints.length - 1].formattedCoords}</p>
      <p>{measurementPoints.length > 0 && (
        measurementPoints[measurementPoints.length - 1].latlng.lat.toFixed(6) + ' / ' + measurementPoints[measurementPoints.length - 1].latlng.lng.toFixed(6)
      )}</p>
      <p>{measurementPoints.length === 0 && (
        <span>Veuillez placer un point sur la carte</span>
      )}</p>
      <h3 className="text-md font-semibold mt-2">Distance du chemin</h3>
      {measurementPoints.length > 1 && (
        <p>{formatDistance(calculateDistance(measurementPoints.map(p => p.latlng)))}</p>
      )}
      {measurementPoints.length <= 1 && (
        <p>Placez plus de points pour commencer à mesurer la distance</p>
      )}
      <h3 className="text-md font-semibold mt-2">Superficie</h3>
      {measurementPoints.length > 2 && (
        <p>{formatArea(calculateArea(measurementPoints.map(p => p.latlng)))}</p>
      )}
      {measurementPoints.length <= 2 && (
        <p>Placez plus de points pour commencer à mesurer la surface</p>
      )}
      <div className="flex justify-between mt-2">
        <button
          onClick={handleCancelMeasurement}
          className="bg-red-500 text-white px-2 py-1 rounded w-full md:w-auto mr-1 flex items-center justify-center"
        >
          {measurementPoints.length === 0 ? (
            <>
              <XMarkIcon className="h-5 w-5 mr-1 inline" aria-hidden="true" /> Fermer
            </>
          ) : (
            <>
              <ArrowUturnLeftIcon className="h-5 w-5 mr-1 inline" aria-hidden="true" /> Annuler la mesure
            </>
          )}
        </button>
        <button
          onClick={handleFinishMeasurement}
          className="bg-green-500 text-white px-2 py-1 rounded w-full md:w-auto ml-1 flex items-center justify-center"
        >
          <CheckIcon className="h-5 w-5 mr-1 inline" aria-hidden="true" /> Finir la mesure
        </button>
      </div>
    </div>
  );
};
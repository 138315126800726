import React, { useEffect, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { MeasurementPoint } from '../types/MeasurementPoint';
import { Measurement } from '../types/Measurement';
import { formatCoordinates } from '../utils/measurementUtils';

interface MapEventsProps {
  isMeasuring: boolean;
  measurements: Measurement[];
  handleMapClick: (e: L.LeafletMouseEvent) => void;
  handleMeasurementClick: (measurement: Measurement, index: number) => void;
  handleClosePopup: () => void;
  addMeasurementPoint: (point: MeasurementPoint) => void;
  openPopupId: number | null;
  selectedMeasure: Measurement | null;
}

export const MapEvents: React.FC<MapEventsProps> = ({
  isMeasuring,
  measurements,
  handleMapClick,
  handleMeasurementClick,
  handleClosePopup,
  addMeasurementPoint,
  openPopupId,
  selectedMeasure,
}) => {
  const map = useMap();

  const isClickOnMeasurement = useCallback((latlng: L.LatLng, measurement: Measurement): boolean => {
    if (measurement.points.length === 1) {
      // For point measurements, check if click is within a small radius
      const point = map.latLngToContainerPoint(measurement.points[0]);
      const clickPoint = map.latLngToContainerPoint(latlng);
      return point.distanceTo(clickPoint) <= 10; // 10 pixels radius
    } else if (measurement.points.length === 2) {
      // For line measurements, check if click is close to the line
      const clickPoint = map.latLngToLayerPoint(latlng);
      const lineStart = map.latLngToLayerPoint(measurement.points[0]);
      const lineEnd = map.latLngToLayerPoint(measurement.points[1]);
      
      const distToSegment = L.LineUtil.pointToSegmentDistance(clickPoint, lineStart, lineEnd);
      return distToSegment <= 10; // 10 pixels distance
    } else {
      // For polygons, check if click is inside the polygon
      // return L.polygon(measurement.points).getBounds().contains(latlng); unprecise as bounds are a rectangle
      const clickPoint = map.latLngToLayerPoint(latlng);
      const polygonPoints = measurement.points.map(point => map.latLngToLayerPoint(point));
    
      // Point-in-polygon algorithm
      let inside = false;
      for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
        const xi = polygonPoints[i].x, yi = polygonPoints[i].y;
        const xj = polygonPoints[j].x, yj = polygonPoints[j].y;
      
        const intersect = ((yi > clickPoint.y) !== (yj > clickPoint.y))
          && (clickPoint.x < (xj - xi) * (clickPoint.y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }
    
      return inside;
    }
  }, [map]);

  const handleClick = useCallback((e: L.LeafletMouseEvent) => {
    if (isMeasuring) {
      const newPoint: MeasurementPoint = {
        latlng: e.latlng,
        formattedCoords: formatCoordinates(e.latlng.lat, e.latlng.lng),
      };
      addMeasurementPoint(newPoint);
    } else {
      const clickedMeasurementIndex = measurements.findIndex(measurement => 
        isClickOnMeasurement(e.latlng, measurement),
      );
      if (openPopupId !== null && selectedMeasure) {
        if (clickedMeasurementIndex == openPopupId) {
          handleClosePopup();
          return;
        }
      }


      if (clickedMeasurementIndex !== -1) {
        handleMeasurementClick(measurements[clickedMeasurementIndex], clickedMeasurementIndex);
      } else {
        handleMapClick(e);
        handleClosePopup();
      }
    }
  }, [isMeasuring, measurements, addMeasurementPoint, handleMeasurementClick, handleMapClick, handleClosePopup]);

  useEffect(() => {
    map.on('click', handleClick);
    return () => {
      map.off('click', handleClick);
    };
  }, [map, handleClick]);

  return null;
};
// MeasuredElements.tsx
import React from 'react';
import { Polygon, Popup, Polyline, CircleMarker } from 'react-leaflet';
import L from 'leaflet';
import { Measurement } from '../types/Measurement';
import { formatArea, formatDistance, formatCoordinates } from '../utils/measurementUtils';
import { getPolygonCentroid } from '../utils/mapUtils';

interface MeasuredElementsProps {
  measurements: Measurement[];
  openPopupId: number | null;
  selectedMeasure: Measurement | null;
  handleDeleteMeasurement: (measurement: Measurement) => void;
  handleClosePopup: () => void;
}

export const MeasuredElements: React.FC<MeasuredElementsProps> = ({
  measurements,
  openPopupId,
  selectedMeasure,
  handleDeleteMeasurement,
  handleClosePopup,
}) => {

  return (
    <>
      {measurements.map((measurement, index) => (
        <React.Fragment key={index}>
          {measurement.points.length === 1 && (
            <CircleMarker
              center={measurement.points[0]}
              radius={5}
              fillColor="#FF1493"
              color="#C71585"
              weight={2}
              opacity={1}
              fillOpacity={0.7}
            />
          )}
          {measurement.points.length === 2 && (
            <Polyline 
              positions={measurement.points}
              color="#C71585"
              weight={3}
            />
          )}
          {measurement.points.length > 2 && (
            <Polygon 
              positions={measurement.points}
              fillColor="#FF1493"
              color="#C71585"
              fillOpacity={0.2}
            />
          )}
          {openPopupId === index && selectedMeasure && (
            <Popup
              position={L.latLng(getPolygonCentroid(selectedMeasure.points.map(latLng => [latLng.lng, latLng.lat])))}
              eventHandlers={{
                remove: () => handleClosePopup(),
              }}
            >
              <div>
                <h3 className="text-lg font-bold">
                  {selectedMeasure.points.length === 1 ? 'Position' : 
                    selectedMeasure.points.length === 2 ? 'Mesure de distance' : 
                      'Mesure de superficie'}
                </h3>
                {selectedMeasure.points.length === 1 && (
                  <>
                    <h3 className='font-semibold mt-3 mb-1'>Coordonnées :</h3>
                    <div className='my-0'>{formatCoordinates(selectedMeasure.points[0].lat, selectedMeasure.points[0].lng)}</div>
                    <div className='my-0'>{ selectedMeasure.points[0].lat.toFixed(6) + ' / ' + selectedMeasure.points[0].lng.toFixed(6)}</div>
                  </>
                )}
                {selectedMeasure.points.length === 2 && (
                  <>
                    <h3 className='font-semibold mt-3 mb-1'>Distance :</h3>
                    <div className='my-0'>{formatDistance(selectedMeasure.perimeter)}</div>
                  </>
                )}
                {selectedMeasure.points.length > 2 && (
                  <>
                    <h3 className='font-semibold mt-3 mb-1'>Surface :</h3>
                    <div className='my-0'>{formatArea(selectedMeasure.area)}</div>
                    <h3 className='font-semibold mt-3 mb-1'>Périmètre :</h3>
                    <div className='my-0'>{formatDistance(selectedMeasure.perimeter)}</div>
                  </>
                )}
                <div className='flex flex-row-reverse mt-2'>
                  <button 
                    onClick={(e) => {
                      handleDeleteMeasurement(selectedMeasure);
                      e.stopPropagation(); //click behing the popup otherwise
                    }}
                    className="text-red-500 hover:underline ml-2"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </Popup>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
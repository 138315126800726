import React from 'react';
import { Search, AlertCircle, MapPin } from 'lucide-react';
import { AutocompleteFeature } from '../types/AutocompleteFeature';
import { ParsedParcelleSearched } from '../types/ParcelleSearched';
import { LatLng } from 'leaflet';
import { Parcelle } from '../types/Parcelle';

type SearchResult = AutocompleteFeature | ParsedParcelleSearched;

interface SearchBarProps {
  searchQuery: string;
  searchResults: SearchResult[];
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectResult: (result: SearchResult) => void;
  error: string | null;
  setCenter: (center: [number, number]) => void;
  setZoom: (zoom: number) => void;
  setSelectedAddress: React.Dispatch<React.SetStateAction<LatLng | null>>;
  handleClosePopup: () => void;
  setSelectedParcelle: (parcelle: Parcelle | null) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  searchResults,
  handleSearchChange,
  handleSelectResult,
  error,
  setCenter,
  setZoom,
  setSelectedAddress,
  handleClosePopup,
  setSelectedParcelle,
}) => {
  const isAutocompleteFeature = (result: SearchResult): result is AutocompleteFeature => {
    return 'properties' in result;
  };

  const handleLocationClick = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleClosePopup();
          setSelectedParcelle(null);
          setSelectedAddress(new LatLng(position.coords.latitude, position.coords.longitude));
          setCenter([position.coords.latitude, position.coords.longitude]);
          setZoom(20);
        },
        (errorLocation) => {
          // TODO : Use a toast notification
          console.error('Error getting location:', errorLocation);
          alert('Impossible de récupérer votre position. Veuillez vérifier les paramètres de votre navigateur et réessayer.');
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        },
      );
    } else {
      // TODO : Use a toast notification
      alert('La géolocalisation n\'est pas supportée par votre navigateur.');
    }
  };

  const showWarning = searchQuery.length > 0 && searchQuery.length < 5;

  const catchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div className="absolute top-4 left-4 right-4 md:left-1/2 md:transform md:-translate-x-1/2 md:w-1/3 md:min-w-[300px] z-[1000]">
      <div>
        <div className={'flex items-center space-x-4'}>
          <div className="relative flex-grow">
            <form onSubmit={catchSubmit} autoComplete="off" className={`flex-grow flex ${error ? 'border-red-500 border-2 rounded-md' : ''}`}>
              <input
                type="text"
                placeholder="Rechercher une adresse ou une parcelle..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-grow p-2 rounded-l-md focus:outline-none border-0"
                autoComplete='off'
              />
              <button className="text-white p-2 rounded-r-md bg-indigo-600 hover:bg-indigo-500 border-indigo-600">
                <Search className="h-6 w-6" />
              </button>
            </form>
            {showWarning && (
              <div className="absolute w-full bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-2 rounded mt-1">
                <AlertCircle className="inline-block mr-2" size={16} />
                Veuillez entrer au moins 5 caractères
              </div>
            )}
            {error && searchQuery.length >= 5 && (
              <div className="absolute w-full bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mt-1">
                <AlertCircle className="inline-block mr-2" size={16} />
                {error}
              </div>
            )}
            {searchResults.length > 0 && (
              <ul className="absolute w-full bg-white mt-1 rounded-md shadow-md max-h-60 overflow-auto">
                {searchResults.map((result, index) => (
                  <li
                    key={index}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelectResult(result)}
                  >
                    {isAutocompleteFeature(result) ? (
                      <>
                        {result.properties.label}
                      </>
                    ) : (
                      <>
                        <span className="font-semibold">Parcelle :</span> {result.id} - ({result.surfaceParcelle} m²)
                      </>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button onClick={handleLocationClick} className="p-2 rounded-md shadow-md text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <MapPin className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
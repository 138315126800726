import React from 'react';
import { Ruler } from 'lucide-react';
import { Field, Label, Switch } from '@headlessui/react';

interface MapControlsProps {
  isCadastre: boolean;
  setIsCadastre: (value: boolean) => void;
  handleMeasureClick: () => void;
}

export const MapControls: React.FC<MapControlsProps> = ({
  isCadastre,
  setIsCadastre,
  handleMeasureClick,
}) => {
  return (
        <>
            <div className="absolute top-20 md:top-4 right-4 flex flex-col items-end space-y-2 z-[1000]">
                <div className="flex items-center space-x-2 bg-white p-2 rounded-md shadow-md">
                  <Field className="flex items-center">
                    <Switch
                    checked={isCadastre} 
                    onChange={() => setIsCadastre(!isCadastre)} 
                      className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                    >
                      <span
                        aria-hidden="true"
                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                      />
                    </Switch>
                    <Label as="span" className="ml-3 text-sm">
                      <span className="font-medium text-gray-900">Cadastre</span>
                    </Label>
                  </Field>
                </div>
            </div>
            <div className="absolute top-20 md:top-4 left-4 flex flex-col items-start space-y-2 z-[1000]">
                <button onClick={handleMeasureClick} className={'p-2 rounded-md shadow-md text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>
                    <Ruler className="h-6 w-6" />
                </button>
            </div>
        </>
  );
};
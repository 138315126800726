import React, { useEffect, useState } from 'react';
import { CircleMarker, Polygon, Polyline, useMap } from 'react-leaflet';
import { MeasurementPoint } from '../types/MeasurementPoint';

interface MeasurementPointsProps {
  points: MeasurementPoint[];
  isActive: boolean;
}

export const MeasurementPoints: React.FC<MeasurementPointsProps> = ({ points, isActive }) => {
  const [mousePosition, setMousePosition] = useState<L.LatLng | null>(null);
  const map = useMap();

  useEffect(() => {
    if (!isActive) {
      setMousePosition(null);
      return;
    }

    const updateMousePosition = (e: L.LeafletMouseEvent) => {
      setMousePosition(e.latlng);
    };

    map.on('mousemove', updateMousePosition);

    return () => {
      map.off('mousemove', updateMousePosition);
    };
  }, [map, isActive]);

  return (
        <>
            {points.map((point, index) => (
                <CircleMarker
                    key={index}
                    center={point.latlng}
                    radius={5}
                    fillColor="#FF1493" // Deep pink
                    color="#C71585" // Medium violet red (for the border)
                    weight={2}
                    opacity={1}
                    fillOpacity={0.7}
                />
            ))}
            {points.length > 1 && (
                <Polyline
                    positions={points.map(point => point.latlng)}
                    color="#FF1493" // Deep pink
                    weight={3}
                    opacity={0.7}
                />
            )}
            {points.length > 2 && (
                <>
                    {/* Last line thinner connecting first and last points */}
                    <Polyline
                        positions={[points[0].latlng, points[points.length - 1].latlng]}
                        color="pink"
                        weight={1}
                        opacity={0.5}
                    />
                    {/* Polygon to fill the area */}
                    <Polygon
                        positions={points.map(point => point.latlng)}
                        fillColor="#FF1493" // Deep pink
                        color="#C71585" // Medium violet red (for the border)
                        fillOpacity={0.2}
                        weight={0}
                    />
                </>
            )}
            {isActive && mousePosition && (
                <CircleMarker
                    center={mousePosition}
                    radius={5}
                    fillColor="#FF1493" // Deep pink
                    color="#C71585" // Medium violet red (for the border)
                    weight={1}
                    opacity={1}
                    fillOpacity={0.4}
                />
            )}
        </>
  );
};
import React from 'react';
import { Popup } from 'react-leaflet';
import { Parcelle } from '../types/Parcelle';
import { Address } from '../types/Address';
import { getPolygonCentroid } from '../utils/mapUtils';

interface SelectedParcellePopupProps {
  selectedParcelle: Parcelle | null;
  setSelectedParcelle: (parcelle: Parcelle | null) => void;
  parcelleAddresses: Address[];
  isLoading: boolean;
}


export const SelectedParcellePopup: React.FC<SelectedParcellePopupProps> = ({ 
  selectedParcelle,
  setSelectedParcelle,
  parcelleAddresses,
  isLoading,
}) => {
  if (!selectedParcelle || isLoading) return null;

  const { lat, lng } = getPolygonCentroid(selectedParcelle.geometry.coordinates[0][0]);

  return (
    <Popup
      position={[lat, lng]}
      className="custom-popup"
      eventHandlers={{
        remove: () => {
          setSelectedParcelle(null);
        },
      }}
    >
      <div className="grid grid-cols-2 gap-2 mb-4 min-w-60">
        <div className="text-gray-600 font-semibold">Section n°</div>
        <div className="text-right">
          {selectedParcelle.commune + selectedParcelle.prefixe + selectedParcelle.section}
        </div>
        <div className="text-gray-600 font-semibold">Parcelle n°</div>
        <div className="text-right">{selectedParcelle.id}</div>
        <div className="text-gray-600 font-semibold">Surface (m²)</div>
        <div className="text-right">{selectedParcelle.surfaceParcelle}</div>
      </div>
      {parcelleAddresses.length > 0 && (
        <>
          <div className="text-gray-600 font-semibold mb-2">Adresse(s) :</div>
          <div className="mb-4 max-h-36 overflow-y-scroll">
            {parcelleAddresses.map((address) => (
              <div key={address.id} className="mb-2">
                <div>
                  {address.numero}
                  {address.indiceRepetition && ` ${address.indiceRepetition}`} {address.nomVoie}
                </div>
                <div>{address.codePostal} {address.commune}</div>
              </div>
            ))}
          </div>
        </>
      )}
      {parcelleAddresses.length === 0 && (
        <div className="text-gray-600 font-semibold mb-2">Aucune adresse associée.</div>
      )}
      <a 
        href={`https://www.google.com/maps?q=${lat},${lng}`}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded mb-2 text-center"
      >
        <span className='text-white'>Voir dans Google Maps</span>
      </a>
      <a 
        href={`https://www.geoportail-urbanisme.gouv.fr/map/#tile=1&lon=${lng}&lat=${lat}&zoom=19&mlon=${lng}&mlat=${lat}`}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full bg-teal-500 hover:bg-teal-600 font-bold py-2 px-4 rounded mb-2 text-center"
      >
        <span className='text-white'>Consulter le PLU</span>
      </a>
      <a 
        href={`https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi/rapport2?typeForm=adresse&lon=${lng}&lat=${lat}`}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full bg-orange-500 hover:bg-orange-600 font-bold py-2 px-4 rounded mb-2 text-center"
      >
        <span className='text-white'>Consulter Géorisques</span>
      </a>
      <button
        onClick={() => {
          const url = `${window.location.origin}${window.location.pathname}?search=${selectedParcelle.id}`;
          navigator.clipboard.writeText(url).then(() => {
            const copyButton = document.getElementById('copy-button');
            if (copyButton) {
              copyButton.innerText = 'Parcelle copiée !';
            }
          });
        }}
        id="copy-button"
        className="block w-full bg-gray-500 hover:bg-gray-600 font-bold py-2 px-4 rounded text-center text-white"
      >
        Partager la parcelle
      </button>
    </Popup>
  );
};
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { userAutoAuth, userInfo } from './store/userSlice';
import MapComponent from './components/MapComponent';
import LoginForm from './components/LoginForm';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const ENV: string = import.meta.env.VITE_ENVIRONMENT;

Sentry.init({
  dsn: 'https://a6d55a9ae5bda78bef45aa2892acde88@demeter.capturama.immo/3',
  environment: ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, 
  tracePropagationTargets: [
    'localhost',
    /^(https?:\/\/(cadastre)\.capturama\.(immo|fr))/,
    'https://demeter.capturama.immo',
  ],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0,
  enabled: ENV !== 'local',
});

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(state => state.user.token);
  const user = useAppSelector(state => state.user.info);
  const [initialSearch, setInitialSearch] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenUrl = params.get('token');

    if (tokenUrl) {
      dispatch(userAutoAuth(tokenUrl));
      params.delete('token');
    }

    // Update URL without token and search
    const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}${window.location.hash}`;
    window.history.replaceState({}, document.title, newUrl);
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(userInfo());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!initialSearch && user && token && !loaded) {
      const params = new URLSearchParams(window.location.search);
      const address = params.get('search');
      if (address) {
        setInitialSearch(address);
        params.delete('search');
        const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}${window.location.hash}`;
        window.history.replaceState({}, document.title, newUrl);
      } else if (user.agence && user.agence.ville && user.agence.adresse && user.agence.cp) {
        setInitialSearch(`${user?.agence?.adresse} ${user?.agence?.ville} ${user?.agence?.cp}`);
      }
      setLoaded(true);
    }
  }, [user, token]);

  if (!token || !user) {
    return <LoginForm />;
  }

  return (
    <MapComponent initialSearch={initialSearch} setInitialSearch={setInitialSearch} />
  );
};

export default App;

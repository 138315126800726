import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';
import { User } from '../types/User';
import { AutoAuthResponse, ExceptionFormResponse, ExceptionResponse, LoginResponse } from '../types/AuthApiResponses';
import Cookies from 'js-cookie';

const ENV: string = import.meta.env.VITE_ENVIRONMENT;
const SUBDOMAIN_SHARED_COOKIE: string = import.meta.env.VITE_SUBDOMAIN_SHARED_COOKIE;
const API_INFOS_USER_URL: string = import.meta.env.VITE_API_INFOS_USER_URL;
const API_JWT_TOKEN_URL: string = import.meta.env.VITE_API_JWT_TOKEN_URL;
const API_LOGIN_URL: string = import.meta.env.VITE_API_LOGIN_URL;

interface UserState {
  processing: boolean,
  info?: User,
  token?: string | null,
  error?: string | null,
}

const initialState: UserState = {
  processing: false,
  token: Cookies.get('jwt_token') || null,
};

interface LoginParams {
  email: string,
  password: string,
  rememberMe: boolean
}

export const userAuth = createAsyncThunk(
  'user/auth',
  async ({ email, password, rememberMe }: LoginParams) => {
    const response = await fetch(API_LOGIN_URL, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ username: email, password, rememberMe }),
    });

    if (!response.ok) {
      return Promise.reject(await response.json() as ExceptionResponse | ExceptionFormResponse);
    } else {
      const loginResponse = await response.json() as LoginResponse;

      let daysToExpire = 1;
      if (rememberMe) {
        daysToExpire = 14; // Like in API
      }

      if (ENV === 'local') {
        Cookies.set('jwt_token', loginResponse.token, { expires: daysToExpire });
      } else {
        Cookies.set('jwt_token', loginResponse.token, { expires: daysToExpire, domain: SUBDOMAIN_SHARED_COOKIE, sameSite: 'None', secure: true });
      }   
  
      return loginResponse;
    }
  },
);

export const userAutoAuth = createAsyncThunk(
  'user/autoAuth',
  async (tokenUrl: string) => {
    const response = await fetch(API_JWT_TOKEN_URL + tokenUrl, {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    });

    if (!response.ok) {
      return Promise.reject(await response.json() as ExceptionResponse | ExceptionFormResponse);
    } else {
      const autoAuthResponse = await response.json() as AutoAuthResponse;
      if (ENV === 'local') {
        Cookies.set('jwt_token', autoAuthResponse.value, { expires: 1 });
      } else {
        Cookies.set('jwt_token', autoAuthResponse.value, { expires: 1, domain: SUBDOMAIN_SHARED_COOKIE, sameSite: 'None', secure: true });
      }      
      return autoAuthResponse;
    }
  },
);

export const userInfo = createAsyncThunk(
  'user/me',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const token = state.user?.token;
    if (!token) {
      return Promise.reject({ message: 'Vous devez être authentifié.' });
    }
    const response = await fetch(API_INFOS_USER_URL, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        if (ENV === 'local') {
          Cookies.remove('jwt_token');
        } else {
          Cookies.remove('jwt_token', { domain: SUBDOMAIN_SHARED_COOKIE });
        }  
        return Promise.reject({ status: 401, message: 'Données de connexion invalides.' });
      }
      return Promise.reject(await response.json() as ExceptionResponse | ExceptionFormResponse);
    } else {
      return (await response.json()) as User;
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        if (ENV === 'local') {
          Cookies.set('jwt_token', action.payload, { expires: 1 });
        } else {
          Cookies.set('jwt_token', action.payload, { expires: 1, domain: SUBDOMAIN_SHARED_COOKIE, sameSite: 'None', secure: true });
        }  
      } else {
        if (ENV === 'local') {
          Cookies.remove('jwt_token');
        } else {
          Cookies.remove('jwt_token', { domain: SUBDOMAIN_SHARED_COOKIE });
        }  
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAuth.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.error = null;
        state.processing = false;
      })
      .addCase(userAuth.pending, (state) => {
        state.processing = true;
        state.error = null;
      })
      .addCase(userAuth.rejected, (state, action) => {
        state.processing = false;
        state.error = action.error.message;
      })
      .addCase(userAutoAuth.fulfilled, (state, action) => {
        state.processing = false;
        state.token = action.payload.value;
        state.error = null;
      })
      .addCase(userAutoAuth.pending, (state) => {
        state.processing = true;
        state.error = null;
      })
      .addCase(userAutoAuth.rejected, (state, action) => {
        state.processing = false;
        state.error = action.error.message;
      })
      .addCase(userInfo.fulfilled, (state, action) => {
        state.processing = false;
        state.info = action.payload;
        state.error = null;
      })
      .addCase(userInfo.pending, (state) => {
        state.processing = true;
        state.error = null;
      })
      .addCase(userInfo.rejected, (state, action) => {
        state.processing = false;
        state.error = action.error.message;
        if (action.payload && typeof action.payload === 'object' && 'status' in action.payload && action.payload.status === 401) {
          state.token = null;
          if (ENV === 'local') {
            Cookies.remove('jwt_token');
          } else {
            Cookies.remove('jwt_token', { domain: SUBDOMAIN_SHARED_COOKIE });
          } 
        }
      });
  },
});

// Export actions
export const { setToken, setError } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
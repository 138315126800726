import { useState, useCallback } from 'react';
import { MeasurementPoint } from '../types/MeasurementPoint';
import { Measurement } from '../types/Measurement';
import { calculatePolygonArea, calculateDistance } from '../utils/measurementUtils';
import { getPolygonCentroid, getZoomLevelForPolygon } from '../utils/mapUtils';

export const useMeasurement = (
  setCenter: (center: [number, number]) => void,
  setZoom: (zoom: number) => void,
) => {
  const [isMeasuring, setIsMeasuring] = useState<boolean>(false);
  const [measurementPoints, setMeasurementPoints] = useState<MeasurementPoint[]>([]);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [selectedMeasurement, setselectedMeasurement] = useState<Measurement | null>(null);
  const [openPopupId, setOpenPopupId] = useState<number | null>(null);

  const handleMeasureClick = useCallback(() => {
    setIsMeasuring(!isMeasuring);
    if (isMeasuring) {
      setMeasurementPoints([]);
    }
  }, [isMeasuring]);

  const addMeasurementPoint = useCallback((point: MeasurementPoint) => {
    setMeasurementPoints(prevPoints => [...prevPoints, point]);
  }, []);

  const handleCancelMeasurement = useCallback(() => {
    if (measurementPoints.length === 0) {
      setIsMeasuring(false);
      setMeasurementPoints([]);
    } else {
      setMeasurementPoints(prevPoints => prevPoints.slice(0, -1));
    }
  }, [measurementPoints.length]);

  const handleFinishMeasurement = useCallback(() => {
    if (measurementPoints.length > 0) {
      const newmeasurement: Measurement = {
        points: measurementPoints.map(p => p.latlng),
        area: calculatePolygonArea(measurementPoints.map(p => p.latlng)),
        perimeter: calculateDistance(measurementPoints.map(p => p.latlng)),
      };
      setMeasurements(prevmeasurements => [...prevmeasurements, newmeasurement]);
      setselectedMeasurement(newmeasurement);
      setOpenPopupId(measurements.length);
    }
    setIsMeasuring(false);
    setMeasurementPoints([]);
  }, [measurementPoints]);

  const handleMeasurementClick = useCallback((measurement: Measurement, index: number) => {
    setselectedMeasurement(measurement);
    setOpenPopupId(index);
    const points = measurement.points.map(p => [p.lat, p.lng]);
    const { lat, lng } = getPolygonCentroid(points);
    setCenter([ lng, lat ]);
    const zoom = getZoomLevelForPolygon(points);
    setZoom(zoom);
  }, []);

  const handleClosePopup = useCallback(() => {
    setselectedMeasurement(null);
    setOpenPopupId(null);
  }, []);

  const handleDeleteMeasurement = useCallback((measurement: Measurement) => {
    setMeasurements(prevmeasurements => prevmeasurements.filter(a => a !== measurement));
    setselectedMeasurement(null);
  }, []);

  return {
    isMeasuring,
    measurementPoints,
    measurements,
    selectedMeasurement,
    openPopupId,
    handleMeasureClick,
    handleCancelMeasurement,
    handleFinishMeasurement,
    handleMeasurementClick,
    handleClosePopup,
    handleDeleteMeasurement,
    addMeasurementPoint,
  };
};